@import '~styleConfig';
$nav-width: 185px;
$test: $nav-width + 1px;

.list_container{
	width: 100%;
	min-width: $nav-width;
	max-height: 541px;
	overflow: hidden;
	li{
		padding: 0 5px;
		padding-bottom: 4px;
		cursor: pointer;
		p, a{
			font-size: 13px;
			font-weight: 400;
			color: #444;
			display: flex;
			align-items: center;
			justify-content: space-between;
			line-height: normal;
			max-width: $nav-width;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
			svg{
				font-size: 15px;
				color: #bbb;
			}
		}
	}
	li:hover{
		p, a{
			font-size: 13px;
			color: $primary-color;
			font-weight: 600;
			svg{
				font-weight: 500;
				color: $primary-color;
			}
		}
		.child_list_container{
			display: block;
			width: 500px;
			background-position: right bottom;
			background-size: contain;
			background-repeat: no-repeat;
		}
	}
	.store_list{
		border-top: 1px solid #efefef;
		padding-top: 5px;
	}
	.store_list_text{
		color: $secondary-color !important;
		&:hover{
			color: $secondary-color;
			text-decoration: underline;
			font-weight: 700;
		}
	}
	.child_list_container{
		display: none;
		position: absolute;
		top: -1px;
		left: $nav-width;
		max-height: 481px;
		width: 0;
		height: calc(100% + 2px);
		background-color: #fff;
		padding: 10px;
		box-shadow: 1px 0px 0px 0 rgba(0,0,0,.13);
		border: 1px solid #bbb;
		overflow: hidden;
		border-top-right-radius: 2px;
		border-bottom-right-radius: 2px;
		-webkit-transition: width 2s; /* For Safari 3.1 to 6.0 */
		-moz-transition: width 2s;
		transition: width 2s;
		ul{
			.child_list a{
				display: block;
				width: 155px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				text-transform: capitalize;
				font-size: 13px;
				font-weight: 400;
				color: #444;
			}
			.all_category a{
				color: $primary-color;
				font-weight: 600;
			}
			li:hover a{
				font-size: 12px;
				color: $primary-color;
				font-weight: 600;
			}
		}
		ul:last-child{
			li:last-child{
				color: $primary-color;
			}
		}
		.wrap_columns{
			display: flex;
		}
	}
}
