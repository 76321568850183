@import "~styleConfig";
.search_wrapper {
    background-color: #fff;
    width: 450px;
    border-radius: 0px 2px 2px 0px;
    height: 40px;
    @include mobile {
        width: 100%;
        height: 32px;
    }
    .search_field {
        padding: 6px 14px;
        @include mobile {
            padding: 2px 10px;
        }
        .input_field {
            color: #1d1c1c;
            font-size: 15px !important;
        }
    }
}
