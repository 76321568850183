@import "~styleConfig";

.root_wrapper {
    margin-bottom: 16px;
}
.cod_outer_wrapper {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2) !important;
    padding-top: 8px;
    background: #fff;
    @include mobile {
        padding-top: 6px;
    }
    .cod_header_wrapper {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f0f0f0;
        padding-left: 16px;
        padding-bottom: 8px;
    }
    .cod_text {
        font-size: 14px;
        font-weight: 500;
    }
    .textfield_outer_wrapper {
        display: flex;
        align-items: center;
        padding-top: 9px;
        padding-bottom: 10px;
        padding-left: 16px;
        @include mobile {
            display: flex;
            margin-left: 10px;
        }
    }
    .icon {
        color: #cecece;
        padding-top: 4px;
        padding-left: 4px;
        svg {
            height: 15px;
            width: 15px;
        }
        &:hover {
            cursor: pointer;
        }
    }
    .pincode_button_wrapper {
        margin-left: 8px;
        width: 100px;
        @include mobile {
            margin-right: 11px;
        }
        .pincode_check_button {
            border: 1px solid #95b3c3;
            height: 37px;
            width: 89px;
            text-transform: none;
            padding-left: 3px;
            padding-right: 3px;
            color: #95b3c3;
            padding-top: 6px;
            box-shadow: 0px 1px rgba(0, 0, 0, 0.2) !important;
            &:hover {
                background: "#fff" !important;
            }
        }
    }
    .helperText {
        font-size: 11px;
        margin-left: 23px;
        padding-bottom: 10px;
        min-height: 1em;
        text-align: left;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 400;
        line-height: 1em;
        letter-spacing: 0.03333em;
        color: #cc1c1c;
    }
}
.textfield_outer_wrapper {
    display: flex;
}
.icon,
.tick_icon,
.cross_icon,
.cancel_icon {
    color: #cecece;
    padding-top: 4px;
    padding-left: 4px;
    svg {
        height: 15px;
        width: 15px;
    }
}
.cancel_icon {
    color: #cc1c1c;
    margin-right: 5px;
}
.tick_icon {
    color: green;
    padding-left: 0px;
    padding-right: 6px;
}
.cross_icon {
    margin-right: 5px;
    color: white;
    svg {
        background: #cc1c1c;
        border-radius: 50%;
    }
}
.arrow_down {
    color: #cecece;
    margin-left: 4px;
    margin-right: 4px;
    svg {
        width: 20px;
        height: 20px;
    }
    &:hover {
        background: #dad8d8;
        height: 20px;
        width: 20px;
        border-radius: 3px;
        color: white;
        cursor: pointer;
    }
}
.cod_availability_wrapper {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    background: #fff;
    padding-top: 9px;
    padding-bottom: 10px;
    padding-left: 16px;
    @include mobile {
        padding-left: 10px;
        padding-top: 6px;
        padding-bottom: 6px;
    }
    .availability_text {
        font-size: 13px;
        color: #6f6f6f;
    }
    .region_text {
        color: #567bb3;
    }
    .cod_info_text {
        font-size: 12px;
        color: #6c6c6c;
        line-height: 1.5;
        list-style-type: disc;
        margin-left: 16px;
    }
    .dot_wrapper {
        height: 10px;
        width: 13px;
        background-color: #6b6b6bdd;
        border-radius: 50%;
        margin-top: 7px;
        margin-right: 12px;
    }
    .error_list_wrapper {
        padding-left: 7px;
        margin-right: 10px;
        background: #f5f5f5;
        padding: 5px;
        border-radius: 4px;
        margin-top: 5px;
        margin-bottom: 2px;
    }
}
.tittle_wrapper {
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: bold;
    padding: 10px 15px;
    color: #464545;
    font-weight: 800;
    padding: 10px 15px;
    .close_icon {
        height: 24px;
        border-radius: 50%;
        border: 1px solid #867e7edd;
        cursor: pointer;
        svg {
            color: #867e7edd;
        }
        @include mobile {
            width: 18px;
            height: 18px;
            svg {
                width: 18px;
                height: 18px;
            }
        }
    }
}
.button_prgress {
    color: #95b3c3 !important;
    width: 20px !important;
    height: 20px !important;
}
.cod_tooltip_text {
    letter-spacing: 0.2px;
}
.expiry_info_text_wrapper {
    padding: 10px 15px;
    font-size: 13px;
    color: #646464;
    line-height: 1.5;
}
.red_error{
    color: #d00!important;
}
