@import '~styleConfig';

.content_wrapper{
    background: #fff;
}
.search_wrapper{
    background: #fff;
    z-index: 1;
    min-height: 50px;
    position: sticky;
    padding: 10px;
    box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.16);
    top: 0px ; //$desktop-header-offset-height;
    @include mobile{
        top: $header-back-height;
    }
}
.ship_note{
    margin: 5px 10px;
    font-size: 20px;
    color: $secondary-color;
}
.list_wrapper{
    display: flex;
    flex-direction: column;
    background: #fff;
    @include mobile{
        flex-direction: column;
    }
    li{
        padding: 10px;
        &:hover{
            cursor: pointer;
            @include desktop{
                color: $secondary-color;
                text-decoration: underline;
            }
        }
    }
}
.list_column{
    width: 90%;
    padding: 10px;
    cursor: pointer;
    @include mobile{
        width: auto;
        padding: 0;
    }
}
.flag_country_wrapper{
    display: flex;
    align-items: center;
    font-size: 17px;
    .flag{
        margin-right: 3px;
    }
    img{
        width: 30px;
        height: 25px;
    }
}
.no_results_container{
    margin-top: 10px;
    padding: 15px;
}
