@import '~styleConfig';

$category-section-width: 115px;
$search-section-width: calc(100% - 120px);

.bottom_section{
    height: $header-bottom-section-height;
    overflow: hidden;
    padding-bottom: 5px;
    .category_button_section{
        width: $category-section-width;
        height: 100%;
        float: left;
        .category_button{
            background: white;
            padding: 0 20px;
            margin-left: 10px;
            height: 100%;
            font-size: 12px;
            border: none;
            border-radius: 5px;
            color: #848484;
            cursor: pointer;
            &:focus{
                outline: none;
            }
        }
    }
    .search_input_section{
        width: $search-section-width;
        height: 100%;
        float: left;
        border-radius: 5px;
        background: #fff;
        color: #21212180;
        padding: 0;
        font-weight: 400;
        font-size: 12px;
        &:hover{
            background-color: white !important;
        }
        .search_input{
            background-color: white;
            height: 100%;
            width: calc(100% - 15px);
            border: none;
            border-radius: 5px;
            padding: 0;
            font-size: 15px;
            padding-left: 5px;
            cursor: pointer;
            &:focus{
                outline: none;
            }
            &:disabled{
                opacity: 1;
                background-color: #fff !important;
            }
        }
        @include mobile{
            justify-content: left;
            padding-left: 15px;
        }
    }
}
