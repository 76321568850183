@import '~styleConfig';

.full_screen_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(255, 255, 255, 0.5);
    z-index: 100;

    .circle_wrapper {
        text-align: center;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .circle {
        color: $secondary-color;
    }
}

.componentLoader_wrapper {
    background: #fff;
    padding-top: 25px;
    text-align: center;

    .componentLoader_circle_wrapper {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .componentLoader_circle {
        color: $secondary-color;
    }
}

.f_componentLoader_wrapper {
    background: #fff;
    height: 60vh;
    padding-top: 25px;
    text-align: center;

    .f_componentLoader_circle_wrapper {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .f_componentLoader_circle {
        color: $secondary-color;
    }
}
