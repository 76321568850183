@import "~styleConfig";

$thumbnail-size: 60px;
.gallery_wrapper {
    background-color: rgb(255, 255, 255);
    .gallery_outer_wrapper {
        min-height: 300px;
        width: 100%;
        background-color: white;
        position: relative;
        @include desktop {
            min-height: 400px;
        }
    }
    .gallery_inner_wrapper {
        transition: max-height 0.3s ease-out;
        height: 345px;
        width: 100%;
        position: relative;
        @include desktop {
            height: 426px;
        }
        button {
            position: absolute;
            right: 12px;
            top: 0;
            @include desktop {
                right: 20px;
                top: 5px;
            }
        }
    }
    .gallery_images_loader_wrapper {
        // height: 345px;
        height: auto;//------------
        width: 100%;
        overflow: hidden;
        // position: relative;
        display: flex;
        flex-direction: column;
        align-items: center !important;
        @include desktop {
            width: 95%;
            margin: auto;
            height: inherit;
        }
    }
    .gallery_loader_wrapper {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 35px;
        height: 35px;
        transform: translate(-50%, -50%);
        z-index: 10;
        display: none;
        .loader {
            color: rgb(85, 85, 85);
        }
    }
    .gallery_images_wrapper {
        transition: opacity 0.3s ease;
        cursor: zoom-in;
    }

    /*********BigImages*********/
    .big_images_wrapper {
        // height: 275px;
        height: auto;//-----------
        width: 100%;//------------
        transition: transform 0.3s cubic-bezier(0.66, 0.14, 0.12, 0.9);
        display: table;
        @include desktop {
            width: 100%;
            height: 350px;
        }

        .image_block {
            float: left;
            position: relative;
            // height: 275px;
            height: auto;
            width: 100%;
            background-image: url(https://img1a.flixcart.com/www/linchpin/batman-returns/images/fk-default-image-75ff34.png);
            background-position: center;
            background-size: 40px 40px;
            background-repeat: no-repeat;
            @include desktop {
                height: 340px;
            }
        }
        .image {
            height: 100%;
            width: 100%;
            position: relative;
            max-width: 200px;
            max-height: 200px;
            opacity: 0;
            transition: none;
            object-fit: contain;//-------------
            @include desktop {
                position: relative;
                max-width: 340px;
                max-height: 340px;
            }
        }
        .image_opacity {
            opacity: 1;
        }
    }
    /*********BigImages*********/

    /*********SmallImages*********/
    .small_image_wrapper {
        width: 100%;
        // height: $thumbnail-size;
        height: auto;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        bottom: 5px;
        // margin-left: 15px;
        overflow: hidden;
        @include desktop {
            height: 70px;
            margin-left: 0;
            padding-left: 10px;
        }
        .small_image_block {
            position: relative;
            width: fit-content;
            height: auto;
            margin-right: 10px;
            border-radius: 3px;
            border: 1px solid #dadada;
            overflow: hidden;
            display: -webkit-box;
            display: -webkit-flex;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            align-items: center;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            @include desktop {
                width: $thumbnail-size;
                height: $thumbnail-size;
                margin-top: 10px;
                cursor: pointer;
            }
        }
        .small_image {
            max-width: 40px;
            max-height: 40px;
        }
        .gallery_active_image {
            border: 2px solid $primary-color;
        }
        .last_small_image::before {
            content: attr(data-length);
            position: absolute;
            left: 0;
            top: 0;
            width: $thumbnail-size;
            height: $thumbnail-size;
            background: rgba(0, 0, 0, 0.5);
            color: white;
            font-size: 16px;
            text-align: center;
            padding-top: 30%;
        }
    }
    /*********SmallImages*********/

.small_images_wrapper {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 80px;
    background: rgba(200, 200, 200, 0.6);
    z-index: 8;
    transform: translate3d(0, 100%, 0);
    transition: transform 0.15s ease-out;
}
.show_images {
    will-change: transform;
    transform: translate3d(0, 0%, 0);
}
.small_images {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 5px;
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
}
.image_wrapper {
    display: inline-block;
    cursor: pointer;
    width: 65px;
    height: 65px;
    margin-left: 10px;
    background: white;
    text-align: center;
    border: 1px solid transparent;
    position: relative;
}

.active_image {
    border-color: $secondary-color;
}
}