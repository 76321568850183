@import '~styleConfig';

.info_header_wrapper{
    background-color: #141a21;
    color: #fff;
    padding: 3px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a{
        color: #fff;
    }
    @include mobile{
        position: sticky;
        top: 0;
        z-index: 10;
        padding: 3px 3px;
    }
    @include desktop{
        justify-content: initial;
        flex-direction: row-reverse;
    }
    .download_icon{
        margin-right: 15px;
    }
}

.phone_number_wrapper{
    // display: flex;
    img{
        display: inline-block;
        width: 13px;
        height: 13px;
        margin-right: 4px;
    }
    a{
        display: inline-block;
        font-size: 10px;
        @include desktop{
            font-size: 12px;
        }
    }
}

.download_app_wrapper{
    @include desktop{
        margin-right: 70px;
    }
    span{
        font-size: 10px;
        @include desktop{
            font-size: 12px;
        }
        display: inline-block;
        position: relative;
        top: -6px;
        @include desktop{
            margin-right: 15px;
        }
    }
    a:nth-child(even){
        margin-right: 4px;
        @include desktop{
            margin-right: 15px;
        }
    }
    img{
        width: 60px;
        display: inline-block;
    }
}
