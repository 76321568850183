@import '~styleConfig';

.flex_class{
	display: flex;
	justify-content: space-between;
}
.skeleton_box{
	background: #fff;
	padding: 5px;
	margin-top: 10px;
}
