@import '~styleConfig';

.container_wrapper{
    background: #fff;
    padding: 25px;
    font-family: sans-serif;
    font-size: 14px;
    @include mobile{
        padding: 15px;
        margin-bottom: 10px;
    }
}
.flex_class{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}
.order_wrapper{
    padding: 18px;
    border: 1px solid #cecece;
    margin-bottom: 15px;
    width:100%;
}
.mobile_button_wrapper{
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
}
