.back_header_wrapper {
  position: sticky;
  top: 0;
  padding-left: 5px;
  button {
    padding: 4px;
  }
  button span svg {
    font-size: 28px;
    color: #fff;
  }
}
.back_button {
  height: 35px !important;
  width: 35px !important;
  color: #fff !important;
  padding: 0;
}
.logo_wrapper {
  width: 35px;
  padding: 5px;
}
.heading_wrapper {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  max-width: 40%;
  white-space: nowrap;
  color: #fff;
}
.heading {
  text-align: left;
  padding-left: 5px;
  font-size: 20px;
  color: #eaeaea;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search_icon {
  color: white;
  padding: 5px;
  float: right;
  position: relative;
  top: 1px;
}
.auth_icon {
  color: white;
  float: right;
  position: relative;
  top: 7px;
  margin-right: 15px;
  font-size: 20px;
  cursor: pointer;
}
.search_wrapper {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  width: 85%;
}
.currency_icon {
  float: right;
  width: 20px;
  position: relative;
  top: 10px;
  margin-right: 15px;
  cursor: pointer;
}
.reward_icon {
  float: right;
  width: 22px;
  position: relative;
  top: 8px;
  margin-right: 5px;
  cursor: pointer;
}
.cart_icon_button {
  float: right;
  padding: 0;
  width: 40px !important;
  height: 40px !important;
  position: relative !important;
  top: 0px;
  margin-right: 5px !important;
  color: white !important;
  font-size: 18px !important;
  cursor: pointer !important;
  .cart_count {
    span {
      background-color: #dc2929;
      border: 0.5px solid #fff;
      width: 16px;
      height: 16px;
      font-size: 0.55rem;
      top: 0px;
      right: 2px;
      cursor: pointer;
    }
  }
}
.notification_icon {
  margin-left: 5px;
  color: #fff;
  float: right;
  position: relative;
  top: 8px;
  margin-right: 5px;
  cursor: pointer;
}
