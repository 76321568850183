@import "~styleConfig";

body {
    margin: 0;
    padding: 0;
    font-family: Roboto !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @include desktop {
        background-color: #f1f3f6;
    }
    @include mobile {
        background-color: #f2f2f2;
    }
}
img {
    width: 100%;
    display: inherit;
}
p {
    margin: 0;
}
a {
    text-decoration: none;
    cursor: pointer;
    color: #333;
}
*:focus {
    outline: none;
}
ul,
menu,
dir {
    display: block;
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
}

table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
div {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    // overflow: hidden;
}
h1 {
    margin: 0;
    font-size: inherit;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
button {
    border: 0;
}

strong {
    font-weight: 500;
}

.icon {
    font-size: 20px !important;
}
.col {
    display: inline-block;
    vertical-align: top;
}
.dentalkart-col-1 {
    width: 8.33%;
}
.dentalkart-col-2 {
    width: 16.66%;
}
.dentalkart-col-3 {
    width: 25%;
}
.dentalkart-col-4 {
    width: 33.33%;
}
.dentalkart-col-5 {
    width: 41.66%;
}
.dentalkart-col-6 {
    width: 50%;
}
.dentalkart-col-7 {
    width: 58.33%;
}
.dentalkart-col-8 {
    width: 66.66%;
}
.dentalkart-col-9 {
    width: 75%;
}
.dentalkart-col-10 {
    width: 83.33%;
}
.dentalkart-col-11 {
    width: 91.66%;
}
.dentalkart-col-12 {
    width: 100%;
}
