@import "~styleConfig";

.address_link_text{
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #1b5ca2;
    &:hover {
        color: #3503ff;
        cursor: pointer;
        text-decoration: underline;
    }
    @include mobile {
        font-size: 13px;
        text-decoration: underline;
    }
}

.address_link_text {
    margin: 20px 0px;
    @include mobile {
        margin-left: 5px;
    }
}

.dialog_wrapper {
    padding: 10px 20px;
    @include mobile {
        padding: 10px 5px;
    }
}

.tittle_wrapper {
    border-bottom: 1px solid #d6d6d6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: #7d7d7d;
    padding-bottom: 10px;
    @include mobile {
        padding-left: 8px;
    }
}
.cross_icon {
    height: 17px;
    width: 17px;
    border-radius: 50%;
    border: 1px solid #867e7edd;
    cursor: pointer;
    svg {
        color: #867e7edd;
        font-size: 17px;
    }
    @include mobile {
        width: 18px;
        display: flex;
    }
}
.default_text {
    font-size: 13px;
    font-weight: 500;
    color: #7d7d7d;
    margin-top: 10px;
}
.shipping_heading_text {
    font-size: 13px;
    color: #7d7d7d;
    font-weight: 500;
}
.delivery_helper_text {
    line-height: 17px;
    margin: 10px 0px;
    color: #7d7d7d;
    font-size: 12px;
    @include mobile {
        padding-left: 8px;
    }
}
.or_text {
    margin: auto;
    text-align: center;
    color: #7d7d7d;
    font-size: 16px;
    font-weight: 500;
}
.shipping_content_text,
.active_address {
    border: 1px solid #f0f0f0;
    line-height: 16px;
    border-collapse: collapse;
    padding: 8px;
    vertical-align: middle;
    overflow: hidden;
    font-size: 14px;
    color: #5f5f5f;
    font-weight: bold;
    @include mobile {
        font-size: 12px;
        padding: 10px;
    }
    cursor: pointer;
    &:hover {
        background: #efefef;
    }
}
.active_address {
    border: 1px solid $secondary-color;
    background: #fff8ea;
    &:hover {
        background: #fff8ea;
    }
}

.new_add_link_text {
    font-size: 16px;
}
.continue_button {
    padding: 15px 18px;
    background-color: #f3943d !important;
    text-transform: none !important;
    height: 40px !important;
    margin-bottom: 4px;
    @include mobile {
        padding: 10px 10px;
        margin: auto;
        height: 40px !important;
    }
    &:hover {
        background: $secondary-color;
        box-shadow: 2px 3px 4px #c7c4c4;
    }
}
.continue_text {
    color: white;
    font-size: 12px;
    font-weight: 500;
    @include mobile {
        color: white;
        font-size: 14px;
        font-weight: bold;
        padding: 0px 7px;
    }
}
.login_button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    flex-direction: column;
}
.middle_line {
    width: 100%;
    border-top: 1px solid #f0f0f0;
}
