@import '~styleConfig';

.err_wrapper{
    background: #fff;
    text-align: center;
    padding: 20px 0;
}
.err_image{
    max-width: 500px;
    margin: auto;
}
