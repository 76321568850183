@import '~styleConfig';

$left-section-top-position: $header-back-height + 50px;
$page-max-width: 1680px;

.product_screen_wrapper{
    background: #f3f5f7;
    max-width: $product-detail-screen-container-width;
    margin: auto;
    @include desktop{
        width: 95%;
        margin: auto;
    }
    .product_sections_wrapper{
        @include desktop{
            background: #fff;
            margin-bottom: 20px;
            margin-top:20px;
            display: flex;
            flex-flow: row;
            .left_section{
                width: 35%;
                top: $left-section-top-position;
                flex-flow: column;
                position: sticky;
                bottom: 0;
                z-index: 2;
                -ms-flex-item-align: start;
                align-self: flex-start;
            }
            .middle_section{
                width: 40%;
                padding:20px;
                border-right:1px solid #dfdfdf;
                border-left:1px solid #dfdfdf;
            }
            .right_section{
                width: 25%;
                padding: 0 10px;
                margin-top: 10px;
            }
        }
    }
}

.skeleton_loader{
    .product_screen_wrapper_placeholder{
        background: #f3f5f7;
        max-width: $product-detail-screen-container-width;
        margin: auto;
        @include mobile{
            background: #fff;
        }
    }
    .big_image_placeholder_wrapper{
        padding: 10px;
    }
    .small_image_placeholder_wrapper{
        display: flex;
        padding: 10px;
    }
    .small_image_placeholder{
        margin-right: 10px;
    }
    .filter_placeholder_wrapper{
        width: 20%;
        background: #fff;
        padding: 10px;
        height: 800px;
        margin: 0 10px;
        box-shadow: 0 1px 8px 0 rgba(0,0,0,.06);
        span{
            margin-bottom: 8px;
        }
    }
    .breadcrumb_placeholder_wrapper{
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        span{
            margin-right: 15px;
        }
    }
    .content_placeholder_wrapper{
        display: flex;
        flex-direction: column;
        span{
            margin-bottom: 8px;
        }
    }
    .quantity_placeholder_wrapper{
        align-items: center;
        display: flex;
        margin-bottom: 10px;
        span{
            margin-right: 10px;
        }
    }
    .product_placeholder_wrapper{
        width: 78%;
        background: #fff;
        height: 900px;
        overflow: hidden;
        @include mobile{
            width: 100%;
        }
        .heading_placeholder_wrapper{
            padding: 15px 15px 0;
            border-bottom: 1px solid #efefef;
        }
        .products_placeholder_wrapper{
            display: flex;
            flex-wrap: wrap;
            .product_placeholder{
                width: 25%;
                height: 400px;
                border-right: 1px solid #efefef;
                border-bottom: 1px solid #efefef;
                @include mobile{
                    width: 100%;
                    height: 130px;
                    border-right: 0;
                    display: grid;
                    grid-auto-columns: 130px auto;
                    grid-auto-flow: column;
                }
            }
            .image_placeholder_wrapper{
                height: 250px;
                padding: 25px;
                @include mobile{
                    padding: 15px;
                    height: 130px;
                }
            }
            .details_placeholder_wrapper{
                padding: 0 25px;
                @include mobile{
                    padding: 15px;
                }
                span{
                    margin-bottom: 8px;
                }
            }
        }
    }
}