@import '~styleConfig';

.top_section{
    height: $header-top-section-height;
    overflow: hidden;
    .top_left_section{
        width: $width/2;
        height: 100%;
        float: left;
        overflow: hidden;
        .drawer_icon{
            padding-left: 10px;
            float: left;
            width: 30px;
            position: relative;
            top: 9px;
            cursor: pointer;
        }
        .dentalkart_logo{
            margin-left: 10px;
            float: left;
            width: 110px;
            position: relative;
            top: 5px;
            cursor: pointer;
        }
    }
    .top_right_section{
        width: $width/2;
        height: 100%;
        display: flex;
        padding-right: 5px;
        flex-direction: row-reverse;
        align-items: center;
        overflow: hidden;
        .notification_icon{
            margin-right: 15px;
            color: #fff;
            cursor: pointer;
        }
        .cart_icon{
            margin-right: 10px;
            color: white;
            font-size: 18px;
            cursor: pointer;
            .cart_count{
                span{
                    background-color: #dc2929;
                    border: .5px solid #fff;
                    width: 16px;
                    height: 16px;
                    font-size: 0.70rem;
                    top: 2px;
                    right: 4px;
                    cursor: pointer;
                }
            }
        }
        .auth_icon{
            color: white;
            margin-right: 15px;
            font-size: 18px;
            cursor: pointer;
        }
        .currency_icon{
            float: right;
            width: 20px;
            position: relative;
            top: 7px;
            margin-right: 15px;
            cursor: pointer;
        }
        .reward_icon{
            float: right;
            width: 20px;
            position: relative;
            top: 5px;
            margin-right: 15px;
            cursor: pointer;
        }
    }
}
