@import '~styleConfig';

.route_content{
    min-height: calc(100vh - 200px);
    @include mobile{
    	min-height: 100vh;
    }
}
.marqueeText{
    color: green;
    margin-top: 10px;
}

.whatsapp {
    height: 60px;
    width: 60px;
    position: fixed;
    z-index: 999999999;
    bottom: 10px;
    right: 10px;
}
