@import "~styleConfig";
$left-section-top-position: $header-back-height;

.product_sections_wrapper {
  // position: relative;
  background: #fff;
  margin: 20px 0px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  .left_section {
    width: 35%;
    flex-flow: column;
  }
  .sticky_pos_class {
    top: $left-section-top-position;
    position: sticky;
    bottom: 0;
    z-index: 2;
  }
}

.product_container {
  background-color: #fff;
  @include mobile {
    padding: 6px;
  }
}
