@import "~styleConfig";

.cookies {
  background-color: #fff;
  position: fixed;
  z-index: 999999999;
  bottom: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(45, 35, 66, 0.35),
    0 7px 13px -3px rgba(45, 35, 66, 0.25);
  border: 1px solid #f5f5fa;
  border-radius: 9999px;
  @include mobile {
    padding: 10px;
    right: 10px;
  }
  &.hide {
    opacity: 0;
    visibility: hidden;
    transition: 0.3s all ease;
  }
}

.cookies_text {
  display: block;
  text-align: left;
  padding: 0 16px;
  line-height: 1.78;
  width: 350px;
  @include mobile {
    width: 100%;
    font-size: 10px;
    line-height: 1.5;
  }
  font-size: 14px;
  font-weight: 400;
}

.learn_more_text {
  text-decoration: underline;
  color: #f3943d;
  cursor: pointer;
}
.btns {
  display: flex;
  align-items: center;
}
.cookies_button {
  width: 36px;
  height: 36px;
  background-color: #25303c;
  border-radius: 50%;
  color: #fff;
  // box-shadow: 0 2px 4px rgba(242, 146, 59, 1);
  cursor: pointer;
  margin-right: 5px;
  @include mobile {
    width: 30px;
  height: 30px;
  }
}
.cookies_button_text {
  font-size: 15px;
  font-weight: 800;
  @include mobile {
    font-size: 12px;
  }
}
.cancel {
  cursor: pointer;
  font-size: 44px !important;
  @include mobile {
    font-size: 36px !important;
  }
}