@import "~styleConfig";

.desktop_header {
  width: 100%;
  position: sticky;
  top: 0;
  height: 55px;
  background: $primary-color;

  .header_wrapper {
    max-width: $container-max-width;
    margin: auto;
    height: 100%;
    width: 100%;
  }
  // .text{
  //     font-weight: 600;
  //
  // }

  .header {
    margin: auto;
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;

    .dentalkart_logo {
      width: 10%;
      margin: 0 15px;
    }

    .country_wrapper {
      position: relative;
    }

    .country {
      margin: 0 15px;
      margin-left: 0px;
      position: relative;
      top: 2px;
      img {
        width: 20px;
      }
    }
    .add_pointer{
      cursor: pointer;
    }
    .shop_by_category_wrapper {
      position: relative;

      .shop_by_category {
        height: 40px;
        font-weight: 600;
        font-size: 11px;
        cursor: pointer;
        text-align: center;
        background-color: $secondary-color;
        border-radius: 2px 0 0px 2px;
        color: #fff;
        display: flex;
        align-items: center;

        svg {
          color: #fff;
          font-size: 15px;
          padding-right: 5px;
        }
      }

      .navigation_container {
        display: none;
        position: absolute;
        top: 40px;
        left: 0px;
        background-color: #fff;
        padding-top: 5px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
        border: 1px solid #bbb;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }
    }

    .shop_by_category_wrapper:hover {
      .navigation_container {
        display: block;
      }
    }

    .search_wrapper {
      width: 38%;
      background-color: #fff;
      border-radius: 0px 2px 2px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      height: 40px;
      cursor: pointer;

      .search_input_placeholder {
        padding-left: 5px;
        color: #21212180;
        font-size: 15px;
      }

      button {
        padding: 0;
        border-radius: 0;
      }

      .search_button_wrapper {
        margin: 0;
        padding: 0;
      }

      .search_button {
        border: 2px solid #fff;
        border-left: 2px solid #f1f3f6;
        border-radius: 0 2px 2px 0;
        height: 36px;
        min-width: 36px;
        cursor: pointer;
        width: 100%;
      }

      .search_button:hover {
        background-color: white;
      }
    }

    .sections_wrapper {
      width: 48%;
      display: flex;
      justify-content: center;
      align-items: center;

      .wallet_section,
      .more_section,
      .profile_section,
      .cart_section {
        width: 12%;
        cursor: pointer;
      }

      .login_signup {
        cursor: pointer;
        font-size: 15px;
        align-items: center;
        color: white;
        margin-right: 20px;
      }

      .wallet_section {
        display: flex;
        margin-right: 20px;
      }

      .wallet_amount_wrapper {
        display: flex;
        flex-direction: column;
        font-size: 11px;
        color: white;
      }

      .wallet_icon {
        height: 26px;
        color: white;
      }

      .more_section {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-right: 20px;
        height: 54px;
      }

      .profile_section {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        margin-right: 20px;
        height: 54px;
        width: 100px;
      }

      .username {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .arrow_down {
        height: 17px;
      }

      .country {
        color: #fff;
      }

      .country_modal {
        border-radius: 0;
      }

      .cart_section {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        position: relative;

        .cart_badge {
          position: absolute;
          top: -7px;
          left: 10px;
          font-size: 11px;
          background: #ff6e03;
          border-radius: 22%;
          width: 15px;
          text-align: center;
        }
      }

      .notification_icon {
        margin-left: 15px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}

.list_item_wrapper {
  width: 215px;
  position: relative;
  max-width: 215px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.list_item_wrapper:before {
  content: "";
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-bottom: solid 12px #fff;
  border-left: solid 12px transparent;
  border-right: solid 12px transparent;
}

.list_item_wrapper {
  nav {
    padding: 0;
  }
}

.icon {
  margin: 0;
  color: $secondary-color;
}

.secondary_icon_wrapper {
  right: 10px !important;
}

.material_icon {
  width: 17px !important;
}

.list_item_wrapper {
  svg {
    width: 15px;
  }
}

.menu_list {
  display: none;
  // font-weight: 600;
}

.more_section:hover {
  .menu_list {
    display: block;
    position: absolute;
    top: 55px;
  }

  .arrow_down {
    transform: scaleY(-1);
  }
}

.profile_section:hover {
  .menu_list {
    display: block;
    position: absolute;
    top: 54px;
  }

  .arrow_down {
    transform: scaleY(-1);
  }
}

.wallet_money {
  text-align: center;
}

.dialog_search {
  padding: 17px;
}

/*********** Country Modal **************/
.country_modal_wrapper_outer{
  position: absolute;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
  z-index: 9;
  @include mobile {
    display: none;
  }
}

.country_modal_wrapper {
  position: absolute;
  top: 38px;
  left: -38px;
}

.country_modal_wrapper_hide{
  display: none;
}
.country_modal {
  width: 480px;
  height: 100%;
  background-color: #fff;
  color: #000;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
  z-index: 51;
  @include mobile {
    width: 100%;
  }
  @include desktop {
    &::before {
      content: "";
      position: absolute;
      top: -10px;
      left: 10%;
      transform: translate(-50%, 0);
      width: 0;
      height: 0;
      border-bottom: solid 12px #fff;
      border-left: solid 12px transparent;
      border-right: solid 12px transparent;
    }
  }
  .country_modal_text {
    padding: 5px 10px 0 10px;

    h5 {
      font-size: 14px;
      font-weight: 400 !important;
    }

    .bold_text {
      font-weight: 600;
    }
  }

  .country_change_button {
    display: flex;
    justify-content: flex-end;
    padding: 0 10px 10px 10px;
    z-index: 51;
    .IN_button,
    .other_button {
      background-color: transparent;
      border: 1px solid #bbb;
      border-radius: 4px;
      padding: 10px !important;
      cursor: pointer !important; 
      margin-right: 15px;
      z-index: 51;
      @include mobile{
        font-size: 12px;
      }
      &:hover {
        background-color: rgb(248, 239, 239);
        transition: 0.3s all linear;
      }
    }

    .other_button {
      background-color: #f3943d !important;
      border: none !important;
      color: #fff !important;
      opacity: 0.9;
      &:hover {
        opacity: 1;
      }
    }
  }
}

/*********** Country Modal **************/
