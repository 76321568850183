@import "~styleConfig";

.app_header {
  background-color: $primary-color;
  z-index: $header-z-index;
  padding: 5px 0;
  position: sticky;
  top: 0;
  box-shadow: $header-shadow;
  overflow: hidden;
  @include desktop {
    overflow: initial;
    padding: 0;
  }
  @include mobile {
    height: $header-back-height;
    // if info header else remove top 25px;
    top: 25px;
  }
}
.app_back_header {
  height: $header-back-height;
  overflow: hidden;
}
.app_home_header {
  height: $header-home-height;
  overflow: hidden;
}

//country
.country_wrapper {
  position: relative;
}

.country {
  margin: 0 15px;
  margin-left: 5px;
  position: relative;
  cursor: pointer;
  img {
    width: 15px;
  }
}

.country_modal_wrapper {
  position: absolute;
  top:150px;
  z-index: 100;
}

.location_icon {
  color: white !important;
  padding:0px 5px;
}

.deliver_country {
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
}

.address_text {
  font-size: 13px;
  line-height: 20px;
  color: #fff;
  font-weight: 400;
}
