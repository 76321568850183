@import '~styleConfig';

.cart_skeleton_wrapper{
	margin: auto;
	margin-top: 10px;
	display: flex;
	background: #fff;
	width: 1300px;
	@include mobile{
		width: 100%;
	}
}
.skeleton_left_section{
	width: 70%;
	margin-right: 20px;
	padding: 10px;
	display: flex;
	flex-direction: column;
	span{
		margin-bottom: 8px;
	}
	@include mobile{
		width: 100%;
		margin-right: 0px;
	}
}
.row_skeleton_wrapper{
	display: flex;
	position: relative;
	span{
		margin-right: 10px;
	}
}
.image_skeleton_wrapper{
	width: 200px;
	margin-right: 10px;
	@include mobile{
		width: 50px;
	}
}
.content_quantity_wrapper{
	display: flex;
	justify-content: space-between;
	width: calc(100% - 200px);
	@include mobile{
		width: calc(100% - 50px);
	}
}
.row_content_wrapper{
	display: flex;
	flex-direction: column;
}
.quantity_skeleton_wrapper{
	display: flex;
	flex-direction: column;
	align-items: center;
}
.row_skeleton_action_wrapper{
	position: absolute;
	display: flex;
	bottom: 0;
	    right: 0;
	justify-content: flex-end;
	@include mobile{
		position: relative;
		span{
			margin-right: 10px;
		}
	}
}
.skeleton_totals_wrapper{
	padding: 10px;
	background-color: #fff;
}
.skeleton_totals_row_wrapper{
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
}
