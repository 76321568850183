.prod_Container {
    display: flex;
    align-items: center;
    justify-content: center;
  
    .paper_class {
      border-radius: 12px;
      width: 80vw;
      padding: 0.5rem;
      border: 1px solid rgb(102, 100, 100);
      min-height: 200px;
      padding-top: 15px;
      @media only screen and (min-width: 949px) {
        width: 60vw;
      }
    }
  
    .search_container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
  
    .search_button {
      margin: 0rem 1rem;
      background-color: #f3943d;
      @media only screen and (max-width: 950px) {
       
        margin-top: 5px;
    }
  
  
    }
    .web_btn{
      background-color: #f3943d;
    }
    .data_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: medium;
      line-height: 1.6;
      @media only screen and (max-width: 950px) {
        padding-left: 8px;
    }
    }
  
    .prod_gallery {
      width: 80%;
      // justify-items: center;
    }
  
    .inStock {
      color: green;
    }
  
    .outOfStock {
      color: red;
    }
  
    .details_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      @media only screen and (min-width: 950px) {
       
        padding-left: 40px;
    }
  
    }
  
    .title {
      font-weight: 700;
    }
  
  
  }
  
  .componentLoader_wrapper {
    background: #fff;
    padding-top: 25px;
    text-align: center;
    margin-top: 10px;
  
    .componentLoader_circle_wrapper {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
  
    .componentLoader_circle {
        color: black;
    }
  }
  