$body-background: #f7f7f7;
$color-white: #fff;
$primary-color: #25303c;
$secondary-color: #f3943d;
$tertiary-color: #141a21;
$primary-text-color: #5a5a59;
$primary-button-hover-color: #2872a3;
$width: 100vw;
$header-home-height: 100px;
$header-back-height: 65px;
$delivery-country-height: 25px;
$header-z-index: 10;
$category-left-section-z-index: 11;
$header-shadow: 0px 2px 5px 0px rgb(162, 162, 162);
$header-top-section-height: 35px;
$header-bottom-section-height: $header-home-height - $header-top-section-height - $delivery-country-height;
//$drawer-user-detail-background: url('@assets/sidebar.jpg');
$leftDrawerwidth: 75vw;
$card-shadow: 0 2px 2px 0 rgba(0,0,0,.1);
$font-family: "Roboto";
$product-detail-screen-container-width: 2560px;
$container-max-width: 1300px;
$container-min-width: 950px;

$desktopWidth: 950px;
$mobileWidth: 949px;
$ancor-color: orange;

$desktop-header-offset-height: 55px;

@mixin desktop {
   @media (min-width: #{$desktopWidth}) {
       @content;
   }
}

@mixin mobile {
   @media (max-width: #{$mobileWidth}) {
       @content;
   }
}
