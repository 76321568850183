@import '~styleConfig';

$page-max-width: 1680px;
.category_wrapper{
     width:80%;
     @include mobile{
         width: 100%;
     }
    .skeleton_loader{
        .filter_product_placeholder_wrapper{
            display: flex;
            .filter_placeholder_wrapper{
                width: 20%;
                background: #fff;
                padding: 10px;
                height: 800px;
                margin: 0 10px;
                box-shadow: 0 1px 8px 0 rgba(0,0,0,.06);
                span{
                    margin-bottom: 8px;
                }
            }
            .product_placeholder_wrapper{
                width: 100%;
                background: #fff;
                height: 900px;
                overflow: hidden;
                @include mobile{
                    width: 100%;
                }
                .heading_placeholder_wrapper{
                    padding: 15px 15px 0;
                    border-bottom: 1px solid #efefef;
                }
                .products_placeholder_wrapper{
                    display: flex;
                    flex-wrap: wrap;
                    .product_placeholder{
                        width: 25%;
                        height: 400px;
                        border-right: 1px solid #efefef;
                        border-bottom: 1px solid #efefef;
                        @include mobile{
                            width: 100%;
                            height: 130px;
                            border-right: 0;
                            display: grid;
                            grid-auto-columns: 130px auto;
                            grid-auto-flow: column;
                        }
                    }
                    .image_placeholder_wrapper{
                        height: 250px;
                        padding: 25px;
                        @include mobile{
                            padding: 15px;
                            height: 130px;
                        }
                    }
                    .details_placeholder_wrapper{
                        padding: 0 25px;
                        @include mobile{
                            padding: 15px;
                        }
                        span{
                            margin-bottom: 8px;
                        }
                    }
                }
            }
        }
    }
}